import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["createPlaylistButton", "form"]
  static classes = ["hidden"]

  openForm() {
    this.createPlaylistButtonTarget.classList.add(this.hiddenClass)
    this.formTarget.classList.remove(this.hiddenClass)
  }

  async submitForm(event) {
    event.preventDefault()

    const form = event.target
    const url = form.action
    const formData = new FormData(form)

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/html, application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (response.ok) {
        this.createPlaylistButtonTarget.classList.remove(this.hiddenClass)
        this.refreshFrame()
      } else if (response.status === 422) {
        const html = await response.text()
        this.replaceForm(html)
      } else {
        console.error('Unexpected response status:', response.status)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  replaceForm(html) {
    const formContainer = this.formTarget
    if (formContainer) {
      formContainer.innerHTML = html
    } else {
      console.error('Form container not found')
    }
  }

  refreshFrame() {
    const frame = this.element.closest("turbo-frame")
    if (frame) {
      const src = frame.getAttribute('src')
      frame.setAttribute('src', src)
    } else {
      console.error("Turbo frame not found")
    }
  }
}
