import { Controller } from "@hotwired/stimulus"
import PlayableList from "../player/playable_list"

const sharedState = {
  isPlaying: false,
  isHD: false,
  isFullscreen: false,
  players: []
}

export default class extends Controller {
  static targets = [
    "playButton",
    "pauseButton",
    "previousButton",
    "nextButton",
    "player",
    "progress",
    "progressFull",
    "title",
    "artist",
    "composerName",
    "performerName",
    "trackCover",
    "artistImage",
    "controls",
    "currentTime",
    "duration",
    "hd",
    "fullsize",
    "smallsize",
    "volume",
    "expandDialog",
    "expandToggle",
    "titleLink",
    "artistLink",
    "composerLink",
    "performerLink",
    "playlistName",
    "playlistIndex",
    "playlistLength",
    "playlistLink"
  ]
  static classes = ["show", "hidden", "active"]
  static stickyVideo = false
  static playerMovedToPlaceholder = false

  connect() {
    if (!sharedState.players.includes(this)) {
      sharedState.players.push(this)
      sharedState.playableList = new PlayableList()
    }

    this.playableList = sharedState.playableList
    this.placeholder = document.getElementById('placeholder')
    this.placeholderSpace = document.getElementById('placeholder-space')
    this.header = document.querySelector('[data-type="navtop"]')
    this.playlistControls = document.querySelector('#playlist-controls')
    this.previousWidth = window.innerWidth
    const self = this

    document.addEventListener("turbo:before-render", function (e) {
      self.resetVideoPosition()
    })

    document.addEventListener('turbo:before-visit', (event) => {
      this.expandDialogTarget.classList.remove('!translate-y-0')
      this.expandDialogTarget.classList.remove('sheet')
      this.expandToggleTargets.forEach((item) => {
        item.style.transform = ''
      })
    })

    if (document.querySelector('.track-page') && window.location.hash == '#play') {
      document.querySelector('#placeholder').click()
    }

    this.restoreCurrentTrackPlayer(this, sharedState)
  }

  /*
   * API
   */

  play(event) {
    this.showPlayer()
    if (event.detail.name || event.target.getAttribute('id') == 'placeholder') {
      this.positionVideo()
    } else {
      this.resetVideoPosition()
    }
    this.playableList.playNow(event.detail)
    this.switchTo(this.playableList.currentTrack)
    if (!this.playableList.current.hasNext() && !this.playableList.current.hasPrevious()) {
      this.hidePreviousNextButtons()
    } else {
      this.showPreviousNextButtons()
    }
    this.showControls()
  }

  playOrResume(event) {
    if (this.playableList.current && event.detail.path == this.playableList.current.path) {
      this.resume()
    } else {
      this.play(event)
    }
  }

  positionVideo() {
    const placeholder = document.getElementById("placeholder")
    if (placeholder) {
      const rect = placeholder.getBoundingClientRect()
      Object.assign(this.playerTarget.parentElement.style, {
        display: 'block',
        position: this.stickyVideo ? 'fixed' : 'absolute',
        top: this.stickyVideo ? (this.header.getBoundingClientRect().height + (window.innerWidth >= 1024 ? 20 : 0) + 'px') : (window.scrollY + rect.top + 'px'),
        height: rect.height + 'px',
        width: rect.width + 'px',
        zIndex: 60
      })
      this.playerMovedToPlaceholder = true
    }
  }

  resetVideoPosition() {
    const placeholder = document.getElementById("placeholder")
    if (placeholder) {
      this.playerTarget.parentElement.style = ''
      this.playerMovedToPlaceholder = false
    }
  }

  scroll(event, forceResetFixedElems) {
    // Check if necessary elements exist before proceeding
    if (!this.placeholderSpace || !this.header || !this.placeholder) return

    const isMobile = window.innerWidth <= 768
    // only stick the player if it's a playlist page
    if (document.querySelectorAll('[data-playlist-target="play"]').length == 0 || document.querySelectorAll('#trackPlay').length == 1) return

    // calculate the position where the player changes to "fixed", basically when it reaches the nav
    let targetPosition = this.placeholderSpace.getBoundingClientRect().top - this.header.getBoundingClientRect().height - 20
    if (targetPosition < 0 && !this.placeholder.classList.contains('fixed')) {
      // Store the original dimensions of the placeholder
      const originalHeight = this.placeholder.getBoundingClientRect().height
      const originalWidth = this.placeholder.getBoundingClientRect().width

      // position placeholder-space element in the position of the placeholder to prevent changes of the doc height
      if (window.innerWidth >= 1024) {
        Object.assign(this.header.style, {
          height: this.header.getBoundingClientRect().height + 20 + 'px',
          paddingBottom: '20px'
        })
      }
      Object.assign(this.placeholderSpace.style, {
        height: originalHeight + 'px',
        width: originalWidth + 'px'
      })
      // position placeholder element to fixed at the top of the page below the nav
      Object.assign(this.placeholder.style, {
        position: 'fixed',
        height: originalHeight + 'px',
        width: originalWidth + 'px',
        top: this.header.getBoundingClientRect().height + (window.innerWidth >= 1024 ? 20 : 0) + 'px',  // on desktop, we need 20px more spacing to the top
      })
      // position controls below sticky video, if mobile
      if (isMobile) {
        Object.assign(this.playlistControls.style, {
          top: this.header.getBoundingClientRect().height + (window.innerWidth >= 1024 ? 20 : 0) + this.placeholder.getBoundingClientRect().height + 'px',
        })
        this.playlistControls.classList.add('sticky')
      }
      this.placeholder.classList.add('fixed')
      this.stickyVideo = true
      if (this.playerMovedToPlaceholder) this.positionVideo() // if the player was started, also reposition the player
    } else if ((targetPosition >= 0 && this.placeholder.classList.contains('fixed')) || forceResetFixedElems) {
      // reset everything to it's original styles
      this.placeholder.classList.remove('fixed')
      this.placeholderSpace.style.height = ''
      this.placeholderSpace.style.width = ''
      this.placeholder.style.height = ''
      this.placeholder.style.width = ''
      this.placeholder.style.position = ''
      this.header.style.height = ''
      this.header.style.paddingBottom = ''
      this.stickyVideo = false
      this.playlistControls.classList.remove('sticky')
      if (forceResetFixedElems) this.scroll()
      if (this.playerMovedToPlaceholder) this.positionVideo()
    }
  }

  repositionVideo() {
    const currentWidth = window.innerWidth
    if (currentWidth == this.previousWidth) return
    if (this.stickyVideo) {
      this.scroll(null, true)
    }
    if (this.playerMovedToPlaceholder) {
      this.positionVideo()
    }
  }

  resume() {
    this.playerTarget.play()
  }

  pause() {
    this.playerTarget.pause()
  }

  next() {
    if (!this.playableList.current) return
    let old = this.playableList.currentTrack
    this.playableList.skipForward()
    if (old !== this.playableList.currentTrack) {
      this.switchTo(this.playableList.currentTrack)
      this.dispatch("updateIndex", { detail: this.playableList.current })
    }
  }

  previous() {
    if (!this.playableList.current) return
    let old = this.playableList.currentTrack
    this.playableList.skipBackward()
    if (old !== this.playableList.currentTrack) {
      this.switchTo(this.playableList.currentTrack)
      this.dispatch("updateIndex", { detail: this.playableList.current })
    }
  }

  skipToTrack(event) {
    let indexToPlay = event.detail
    this.playableList.skipToTrack(indexToPlay)
    this.switchTo(this.playableList.currentTrack)
  }

  switchTo(track) {
    if (sharedState.isHD && track.hd_url) {
      this.playSource(track.hd_url)
    } else {
      this.playSource(track.url)
    }
    this.updateInfo(track)
    this.dispatch("play", { detail: track.path })
    if (this.playableList.current) this.dispatch("updateIndex", { detail: this.playableList.current })
    this.setPoster(track)
  }

  playSource(source) {
    this.playerTarget.src = source
    this.playerTarget.play().catch((error) => this.handleError(error))
  }

  handleError(error) {
    this.playerTarget.pause()
  }

  toggleHd(event) {
    event.preventDefault()
    let currentTime = this.currentTime
    sharedState.isHD = !sharedState.isHD
    if (sharedState.isHD) {
      this.playSource(this.playableList.currentTrack.hd_url)
      this.hdTarget.classList.add(this.activeClass)
    } else {
      this.playSource(this.playableList.currentTrack.url)
      this.hdTarget.classList.remove(this.activeClass)
    }
    this.playerTarget.currentTime = currentTime
  }

  toggleFullsize(event) {
    event.preventDefault()
    let target = this.playerTarget.parentElement

    if (!this.isFullscreenSupported(target)) {
      target = this.playerTarget // fallback using the actual video with native controls
    }

    if (!sharedState.isFullscreen) {
      if (target.requestFullscreen) {
        target.requestFullscreen()
      } else if (target.mozRequestFullScreen) {
        target.mozRequestFullScreen()
      } else if (target.webkitRequestFullscreen) {
        target.webkitRequestFullscreen()
      } else if (target.webkitEnterFullscreen) {
        target.webkitEnterFullscreen()
      } else if (target.msRequestFullscreen) {
        target.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  fullscreenChange() {
    if (!document.fullscreenElement && !document.webkitFullscreenElement &&
      !document.mozFullScreenElement && !document.msFullscreenElement) {
      // fullscreen closed
      this.fullsizeTarget.classList.remove(this.hiddenClass)
      this.smallsizeTarget.classList.add(this.hiddenClass)
      sharedState.isFullscreen = false
    } else {
      // fullscreen opened
      this.fullsizeTarget.classList.add(this.hiddenClass)
      this.smallsizeTarget.classList.remove(this.hiddenClass)
      sharedState.isFullscreen = true
    }
  }

  isFullscreenSupported(target) {
    return target.requestFullscreen ||
      target.mozRequestFullScreen ||
      target.webkitRequestFullscreen ||
      target.msRequestFullscreen
  }

  toggleVolume(event) {
    event.preventDefault()
    if (this.volumeTarget.classList.contains(this.activeClass)) {
      this.volumeTarget.classList.remove(this.activeClass)
    } else {
      // fullscreen opened
      this.fullsizeTarget.classList.add(this.hiddenClass)
      this.smallsizeTarget.classList.remove(this.hiddenClass)
      sharedState.isFullscreen = true
    }
  }

  toggleVolume(event) {
    event.preventDefault()
    if (this.volumeTarget.classList.contains(this.activeClass)) {
      this.volumeTarget.classList.remove(this.activeClass)
    } else {
      this.volumeTarget.classList.add(this.activeClass)
    }
  }

  toggleExpand(event) {
    this.expandDialogTarget.classList.toggle('!translate-y-0')
    this.expandDialogTarget.classList.toggle('sheet')
    this.expandToggleTargets.forEach((item) => {
      if (item.style.transform == 'rotate(180deg)') {
        item.style.transform = ''
      } else {
        item.style.transform = 'rotate(180deg)'
      }
    })
    // if we have an impersonation banner, we display the player above
    if (document.querySelector('#impersonation_banner')) {
      this.expandDialogTarget.style.marginBottom = document.querySelector('#impersonation_banner').getBoundingClientRect().height
    }
  }

  changeVolume(event) {
    let value = event.target.value
    this.playerTarget.volume = value / 100
  }

  // On some browsers and devices, programmatic playback is only allowed once
  // the media element has played at least one source in response to user input.
  // We play a silent track to achieve that.
  unlockPlayback() {
    if (sharedState.isPlaying) return
    const silentAudio = "data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA"
    this.playerTarget.muted = true
    this.playerTarget.src = silentAudio
    this.playerTarget.play()
      .catch((error) => this.handleError(error))
      .then(() => {
        this.playerTarget.pause()
        this.playerTarget.muted = false
      })
  }

  /*
   * Events
   *
   * Named after HTMLMediaElement events
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#events
   */

  playing(event) {
    console.log("event: playing")
    this.hidePlayButton()
    this.showPauseButton()
    sharedState.isPlaying = true
    this.broadcastState()
  }

  paused(event) {
    console.log("event: paused")
    this.hidePauseButton()
    this.showPlayButton()
    sharedState.isPlaying = false
    this.broadcastState()
    this.showControls()
  }

  ended(event) {
    console.log("event: ended")
    this.hidePauseButton()
    this.showPlayButton()
    this.next()
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/MediaError
  error(event) {
    handleError(event.error)
  }

  timeupdate(event) {
    this.updateProgress()
  }

  durationchange(event) {
    this.updateProgress()

    this.durationTargets.forEach((item) => {
      item.innerText = this.formatSecondsToMinutes(this.duration)
    })
  }

  /*
   * User interface
   */

  showPlayer() {
    this.element.classList.remove(this.hiddenClass)
    this.element.classList.add(this.showClass)
    // if we have an impersionation banner, position the player above
    if (document.querySelector('#impersonation_banner')) {
      this.element.querySelector('.sheet').style.bottom = document.querySelector('#impersonation_banner').getBoundingClientRect().height + 'px'
    }
  }

  hidePlaceholder() {
    const placeholder = document.getElementById("placeholder")
    if (placeholder) {
      placeholder.classList.add(this.hiddenClass)
    }
  }

  hidePlayButton() {
    this.playButtonTargets.forEach((item) => {
      item.classList.add(this.hiddenClass)
      item.classList.remove(this.showClass)
    })
    this.dispatch("hidePlayButton")
  }

  showPreviousNextButtons() {
    this.previousButtonTargets.forEach((item) => {
      item.classList.add(this.showClass)
      item.classList.remove(this.hiddenClass)
    })
    this.nextButtonTargets.forEach((item) => {
      item.classList.add(this.showClass)
      item.classList.remove(this.hiddenClass)
    })
  }

  hidePreviousNextButtons() {
    this.previousButtonTargets.forEach((item) => {
      item.classList.add(this.hiddenClass)
      item.classList.remove(this.showClass)
    })
    this.nextButtonTargets.forEach((item) => {
      item.classList.add(this.hiddenClass)
      item.classList.remove(this.showClass)
    })
  }

  showPlayButton() {
    this.playButtonTargets.forEach((item) => {
      item.classList.remove(this.hiddenClass)
      item.classList.add(this.showClass)
    })
    this.dispatch("showPlayButton")
  }

  hidePauseButton() {
    this.pauseButtonTargets.forEach((item) => {
      item.classList.remove(this.showClass)
      item.classList.add(this.hiddenClass)
    })
    this.dispatch("hidePauseButton")
  }

  showPauseButton() {
    this.pauseButtonTargets.forEach((item) => {
      item.classList.remove(this.hiddenClass)
      item.classList.add(this.showClass)
    })
    this.dispatch("showPauseButton")
  }

  seeked(event) {
    let x = event.clientX - event.target.getBoundingClientRect().left
    let width = event.target.getBoundingClientRect().width
    let percent = (x / width) * 100
    let newTime = this.duration / 100 * percent
    this.playerTarget.currentTime = newTime
    this.updateProgress()
  }

  updateProgress() {
    const percentage = this.currentTime * 100 / this.duration

    this.progressTargets.forEach((item) => {
      item.style = `width: ${percentage}%`
    })

    this.currentTimeTargets.forEach((item) => {
      item.innerText = this.formatSecondsToMinutes(this.currentTime)
    })
  }

  updateInfo(track) {
    if (this.hasTitleTarget) {
      this.titleTargets.forEach((item) => {
        item.innerText = track.title
      })
    }
    if (this.hasArtistTarget) {
      this.artistTargets.forEach((item) => {
        item.innerText = track.artist
      })
    }
    if (this.hasComposerNameTarget) {
      this.composerNameTargets.forEach((item) => {
        item.innerText = track.composer_name
      })
    }
    if (this.hasPerformerNameTarget) {
      this.performerNameTargets.forEach((item) => {
        item.innerText = track.performer_name
      })
    }
    if (this.hasTrackCoverTarget) {
      this.trackCoverTargets.forEach((item) => {
        item.src = track.picture_url_xlarge
      })
    }
    if (this.hasArtistImageTarget) {
      this.artistImageTargets.forEach((item) => {
        item.src = track.user_picture_url
      })
    }
    if (this.hasTitleLinkTarget) {
      this.titleLinkTargets.forEach((item) => {
        item.href = track.path
      })
    }
    if (this.hasArtistLinkTarget) {
      this.artistLinkTargets.forEach((item) => {
        item.href = track.band_path
      })
    }
    this.composerLinkTargets.forEach((item) => {
      item.href = track.composer_url
    })
    this.performerLinkTargets.forEach((item) => {
      item.href = track.performer_url
    })
    if (this.playableList.current.name) {
      // this is a playlist, so update the playlist data too
      if (this.hasPlaylistNameTarget) {
        this.playlistNameTargets.forEach((item) => {
          item.innerText = this.playableList.current.name
        })
      }
      if (this.hasPlaylistIndexTarget) {
        this.playlistIndexTargets.forEach((item) => {
          item.innerText = this.playableList.current._index + 1
        })
      }
      if (this.hasPlaylistLengthTarget) {
        this.playlistLengthTargets.forEach((item) => {
          item.innerText = this.playableList.current.tracks.length
        })
      }
      if (this.hasPlaylistLinkTarget) {
        this.playlistLinkTargets.forEach((item) => {
          item.href = this.playableList.current.path
        })
      }
    } else {
      // no playlist, empty playlist information
      this.playlistNameTargets.forEach((item) => {
        item.innerText = ''
      })
      this.playlistIndexTargets.forEach((item) => {
        item.innerText = ''
      })
      this.playlistLengthTargets.forEach((item) => {
        item.innerText = ''
      })
    }
  }

  togglePlay() {
    if (sharedState.isPlaying) {
      this.pause()
    } else {
      this.resume()
    }
  }

  setPoster(track) {
    this.playerTargets.forEach(target => { target.setAttribute("poster", track.picture_url_xlarge) })
    if (track.type == 'audio') {
      // Safari hides the poster when a background-color is set on the <video>, that's why we set it manually for audio tracks
      this.playerTargets.forEach(target => { target.style.backgroundImage = `url('${track.picture_url_xlarge}')` })
    }
  }

  showControls(event) {
    if (event) {
      // this was a real mouseenter, we wait for the mouseleave to hide the controls
      this.mouseOnPlayer = true
    } else {
      // this is not based on a mouse event: we hide the controls after 5 seconds,
      // if the mouse is not on the video
      setTimeout(() => {
        if (!this.mouseOnPlayer) {
          this.hideControls()
        }
      }, 5000)
    }
    this.controlsTarget.classList.add(this.showClass)
    this.controlsTarget.classList.remove(this.hiddenClass)
  }

  hideControls(event) {
    if (event) {
      this.mouseOnPlayer = false
    }
    if (!sharedState.isPlaying) return
    this.controlsTarget.classList.add(this.hiddenClass)
    this.controlsTarget.classList.remove(this.showClass)
  }

  broadcastState() {
    sharedState.players.forEach(player => {
      if (player !== this) {
        player.syncState()
      }
    })
  }

  syncState() {
    if (sharedState.isPlaying) {
      this.resume()
    } else {
      this.pause()
    }
  }

  formatSecondsToMinutes(seconds) {
    // Calculate the minutes and seconds
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = Math.floor(seconds % 60)

    // Pad with leading zeros if necessary
    const paddedMinutes = String(minutes).padStart(2, '0')
    const paddedSeconds = String(remainingSeconds).padStart(2, '0')

    // Combine and return the formatted string
    return `${paddedMinutes}:${paddedSeconds}`
  }

  /**
   * this method checks whether the currently played song/playlist is also the page we're currently on
   * if yes, restore the player to show the current progress
   */
  restoreCurrentTrackPlayer(self, sharedState) {
    const isPlaylistPage = document.querySelectorAll('#playlistPlay').length > 0
    const isTrackPage = document.querySelectorAll('#trackPlay').length > 0
    const placeholder = document.querySelector('#placeholder')

    if (!placeholder || !sharedState.playableList.current || (!isPlaylistPage && !isTrackPage)) {
      return
    }

    const trackConfig = JSON.parse(placeholder.getAttribute('data-playable-attributes-value') || '{}')
    const assetPath = trackConfig.playable?.path

    let currentPath
    if (isPlaylistPage && sharedState.playableList.current?.constructor.name === 'Playlist') {
      currentPath = sharedState.playableList.current.path
    } else if (isTrackPage && sharedState.playableList.current?.constructor.name === 'Track') {
      currentPath = sharedState.playableList.current.current?.path
    }

    if (assetPath === currentPath) {
      // wait for the track/playlist cover to load, otherwise we cannot calculate the player height
      const img = placeholder.querySelector('img')
      if (img.complete) {
        this.positionVideo()
      } else {
        img.onload = () => self.positionVideo()
      }
      this.showControls()
      if (isPlaylistPage) {
        this.dispatch("updateIndex", { detail: sharedState.playableList.current })
      } 
    }
  }

  /*
   *
   */

  get currentTime() {
    return this.playerTarget.currentTime
  }

  get duration() {
    return this.playerTarget.duration
  }
}
