import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container"]
  static values = {
    urls: Array
  }

  connect() {
    if (this.hasUrlsValue && this.urlsValue.length > 0) {
      this.generateCover(this.urlsValue)
    }
  }

  generateCover(imageUrls) {
    const cover = this.containerTarget

    if (imageUrls.length === 1) {
      cover.className = 'w-full h-full'
      this.setBackgroundImage(cover, imageUrls[0])
    } else {
      cover.innerHTML = ''
      const wrapper = this.createDiv('w-full h-full flex')
      cover.appendChild(wrapper)

      if (imageUrls.length === 2) {
        imageUrls.forEach(url => {
          const half = this.createDiv('flex w-full h-full')
          this.setBackgroundImage(half, url)
          wrapper.appendChild(half)
        })
      } else if (imageUrls.length === 3) {
        const leftSection = this.createDiv('flex w-full h-full')
        this.setBackgroundImage(leftSection, imageUrls[0])
        wrapper.appendChild(leftSection)

        const rightSection = this.createDiv('flex w-full h-full flex-col')
        imageUrls.slice(1).forEach(url => {
          const rightImage = this.createDiv('flex w-full h-full')
          this.setBackgroundImage(rightImage, url)
          rightSection.appendChild(rightImage)
        })
        wrapper.appendChild(rightSection)
      }
    }
  }

  createDiv(className) {
    const div = document.createElement('div')
    div.className = className
    return div
  }

  setBackgroundImage(element, url) {
    element.style = `
      background-image: url("${url}");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `
  }
}
