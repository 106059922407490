export const cookieHelper = {
  getCookie(name) {
    try {
      let cookieArr = document.cookie.split(";")

      for (let i = 0; i < cookieArr.length; i++) {
          let cookiePair = cookieArr[i].split("=")
  
          if (name == cookiePair[0].trim()) {
              return decodeURIComponent(cookiePair[1])
          }
      }
    } catch(e) {
      return null
    }

    return null
  },

  updateCookie(name, value, expires = (365 * 24 * 60 * 60 * 1000), path = '/') {
    const currentValue = this.getCookie(name)

    if (currentValue != value) {
      document.cookie = name + "=" + value + ";" + expires + "; path=" + path
    }
  }
};
