import { Controller } from "@hotwired/stimulus"
import { cookieHelper } from "../helpers/cookie_helper"

export default class extends Controller {
  static targets = ["menu", "button"]
  static classes = ["dark"]
  static values = { 
    cookieName: String, 
  }

  initialize() {
    let self = this;
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', function(){
      if (cookieHelper.getCookie(self.cookieNameValue) == 'system') {
        let chosenTheme = this.matches ? 'dark' : 'light'
        self.switch(chosenTheme, true)
      }
    });
  }

  change(event) {
    const chosenTheme = event.target.dataset.themeValue
    this.switch(chosenTheme)
  }

  switch(chosenTheme, autodetected) {
    const htmlElement = document.querySelector('html')
    const isSystemDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  
    switch (chosenTheme) {
      case 'dark':
        htmlElement.classList.add(this.darkClass)
        break;
      case 'light':
        htmlElement.classList.remove(this.darkClass)
        break;
      case 'system':
        htmlElement.classList.toggle(this.darkClass, isSystemDarkMode)
        break;
    }
  
    if (!autodetected) {
      cookieHelper.updateCookie(this.cookieNameValue, chosenTheme)
      this.updateButton(chosenTheme)
    }
    
    this.dispatch("closeDropdown", { detail: this.element })
  }

  updateButton(chosenTheme) {
    this.buttonTarget.innerText = this.element.querySelector(`[data-theme-value="${chosenTheme}"] .material-symbols-outlined`).innerText
  }
}
