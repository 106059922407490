import { Controller } from "@hotwired/stimulus"
import { cookieHelper } from "../helpers/cookie_helper"

export default class extends Controller {
  static targets = ["notificationElement", "closeButton"]
  static values = { cookieName: String, id: String }

  close() {
    this.notificationElementTarget.classList.add('hidden')
    this.updateCookie()
  }

  updateCookie() {
    const removedIds = cookieHelper.getCookie(this.cookieNameValue)
    const removedIdArray = removedIds ? JSON.parse(removedIds) : []

    if (!removedIdArray.includes(this.idValue)) {
      removedIdArray.push(this.idValue)
      cookieHelper.updateCookie(this.cookieNameValue, JSON.stringify(removedIdArray))
    }
  }
}
