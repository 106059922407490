import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.autoSelect.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("click", this.autoSelect.bind(this))
  }

  autoSelect(event) {
    event.target.select()
  }
}
