import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "menu", "label" ]
  static classes = [ "show", "hidden" ]

  static backgroundOverlayClasses = ['opacity-60', 'top-0', 'left-0', 'tranlate-x-0', 'h-screen', 'w-screen']
  static mobileDropdownClasses = ['!text-base', '!fixed', 'transition-transform', 'w-screen', 'bottom-0', 'translate-y-full', '!left-0', 'right-auto', '!top-auto', 'max-w-none', 'max-h-none', 'rounded-b-none', '!z-[1000]', 'pt-[40px]', 'pb-[15px]']

  connect() {
    this.hideMenu(true)
  }

  toggle(event) {
    event.stopPropagation()

    if (this.menuTarget.classList.contains(this.showClass)) {
      this.hideMenu()
    } else {
      this.closeAllDropdowns()
      this.showMenu(event)
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.hideMenu()
    }
  }

  select(event) {
    const selectedLabel = event.currentTarget.dataset.dropdownLabel
    const targetId = event.currentTarget.dataset.targetId

    if (this.hasLabelTarget) {
      this.labelTarget.textContent = selectedLabel
    }

    this.hideMenu()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  hideMenu(connecting) {
    this.menuTarget.classList.remove(this.showClass)
    this.menuTarget.classList.add(this.hiddenClass)

    if (connecting) return

    if (this.isSmallScreen()) {
      document.querySelector('.sidebar-bg').classList.remove(...this.constructor.backgroundOverlayClasses)
      this.menuTarget.classList.remove(...this.constructor.mobileDropdownClasses)
      this.menuTarget.style = ''
      document.querySelector('#tabs')?.classList.add('backdrop-blur', 'z-[1]')
    }
  }

  hideMenuInstance(event) {
    if (event.target.closest('a,button')) {
      event.preventDefault()
      event.stopPropagation()
    }
    if (event.detail == this.element || this.element.contains(event.target) ) {
      this.hideMenu()
    }
  }

  showMenu(event) {
    this.menuTarget.classList.add(this.showClass)
    this.menuTarget.classList.remove(this.hiddenClass)
    this.positionDropdown(event)
    this.element.addEventListener('click', (event) => { this.outsideClick(event) })
  }

  closeAllDropdowns() {
    document.querySelectorAll(`[data-controller*="dropdown"] .${this.showClass}`).forEach(menu => {
      menu.classList.remove(this.showClass)
    })
  }

  positionDropdown(event) {
    const menu = this.menuTarget
    const isHeaderNavItem = this.element.closest('header') !== null

    if (this.isSmallScreen() && !isHeaderNavItem) {
      // add the close button for the bottom dropdown
      this.addCloseButton()

      // two classes on the #tabs element break the z-indexes, we remove them for this case
      document.querySelector('#tabs')?.classList.remove('backdrop-blur', 'z-[1]')

      // position background overlay
      document.querySelector('.sidebar-bg').classList.add(...this.constructor.backgroundOverlayClasses)

      // now style the dropdown to be on the bottom fixed
      menu.classList.add(...this.constructor.mobileDropdownClasses)

      // animate dropdown sliding in from the bottom
      setTimeout(() => {
        menu.style.transform = 'translateY(0)'
      }, 0)
    } else {
      // default case for other dropdowns
      const button = event.target.closest('button')
      const menuWidth = menu.getBoundingClientRect().width
      const rect = button.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceRight = window.innerWidth - rect.right
      const isSmallestScreen = window.matchMedia("(max-width: 639px)").matches;

      if (spaceBelow < menu.offsetHeight && rect.top > menu.offsetHeight && !button.closest('.overflow-x-auto')) {
        menu.style.top = 'auto'
        menu.style.bottom = '100%'
        menu.style.marginTop = '0'
        menu.style.marginBottom = '0.25rem'
      } else {
        menu.style.top = isSmallestScreen ? '' : '100%'
        menu.style.bottom = 'auto'
        menu.style.marginTop = isSmallestScreen ? '' : '0.25rem'
        menu.style.marginBottom = '0'
      }

      if (isHeaderNavItem) return

      if (spaceRight < menuWidth) {
        menu.style.left = 'auto';
        menu.style.right = '0';
        menu.style.marginLeft = '0';
        menu.style.marginRight = '0.25rem';
      } else {
        menu.style.left = '';
        menu.style.right = '';
        menu.style.marginLeft = '';
        menu.style.marginRight = '';
      }
    }
  }

  isSmallScreen() {
    return window.matchMedia("(max-width: 767px)").matches;
  }

  addCloseButton() {
    if (this.menuTarget.querySelector('[data-action="dropdown#hideMenuInstance"]')) return

    const closeButtonHTML = `
      <li class="!absolute top-0 right-0 md:hidden">
        <a href="#" data-action="dropdown#hideMenuInstance" class="w-[44px] h-[44px] flex items-center justify-center">
          <span class="material-symbols-outlined font-bold">close</span>
        </a>
      </li>
    `;
    
    this.menuTarget.insertAdjacentHTML('beforeend', closeButtonHTML);
  }

  outsideClick(event) {
    if (!this.menuTarget.contains(event.target) && this.menuTarget != event.target) {
      // Click was outside of dropdown, close it
      event.stopPropagation()
      this.hideMenu()
    }
  }

}
