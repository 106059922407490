import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { dialogId: String }

  async submitForm(event) {
    event.preventDefault()

    const form = event.target
    const url = form.action
    const formData = new FormData(form)

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'text/html, application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success) {
          this.closeDialog()
        }
      } else if (response.status === 422) {
        const html = await response.text()
        this.replaceForm(html)
      } else {
        console.error('Unexpected response status:', response.status)
      }
    } catch (error) {
      console.error('Error:', error.message)
    }
  }

  closeDialog() {
    const dialogElement = document.querySelector(this.dialogIdValue)
    if (dialogElement) {
      const dialogController = this.application.getControllerForElementAndIdentifier(dialogElement, "dialog")
      if (dialogController) {
        dialogController.close()
      } else {
        console.error('Dialog controller not found')
      }
    } else {
      console.error('Dialog element not found')
    }
  }

  replaceForm(html) {
    const formContainer = document.querySelector('[data-controller="booking-request"]')
    if (formContainer) {
      formContainer.innerHTML = html
    } else {
      console.error('Form container not found')
    }
  }
}
