import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    userId: String,
    itemId: String
  }

  updateTurboFrame(event) {
    const turboFrame = document.querySelector('#add-to-playlist turbo-frame')

    if (!this.hasItemIdValue) {
      console.error("Item ID value is not set on this controller")
      return
    }

    if (turboFrame && this.itemIdValue) {
      const domId = `band_${this.userIdValue}-add-to-playlist`
      turboFrame.id = domId

      const urlTemplate = turboFrame.dataset.urlTemplate
      turboFrame.src = urlTemplate.replace('__ITEM_ID__', this.itemIdValue)
    } else {
      console.error("TurboFrame or itemId not found!")
    }
  }
}
