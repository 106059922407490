import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map"]
  static values = {
    src: String,
    div: String,
    latitude: String,
    longitude: String,
    title: String,
    initialized: Boolean
  }

  initialize() {
    this.loadGoogleMapScript()
    
    let self = this
    window.googleMapInitMap = function() {

      window.googleMapInitialized = true
      if (!self.initializedValue) {
        self.initGoogleMap()
      }
    }
  }

  connect() {
    if (window.googleMapInitialized && !self.initializedValue) {
      this.initGoogleMap()
    }
  }

  loadGoogleMapScript() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = this.srcValue
    document.body.appendChild(script)
  }

  initGoogleMap() {
    this.initializedValue = true
    const position = new google.maps.LatLng(this.latitudeValue, this.longitudeValue)
    const mapOptions = { center: position, zoom: 15, mapTypeId: google.maps.MapTypeId.ROADMAP }
    this.map = new google.maps.Map(document.querySelector('#' + this.divValue), mapOptions)
    const marker = new google.maps.Marker({
      position: position,
      map: this.map,
      title: this.titleValue
    })
  }

}
