import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form"]
  static classes = ["hidden"]

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("turbo:submit-end", this.formSubmitEnd.bind(this))
    }
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener("turbo:submit-end", this.formSubmitEnd.bind(this))
    }
  }

  formSubmitEnd(event) {
    if (event.detail.success) {
      this.element.classList.add(this.hiddenClass)
      this.element.addEventListener("transitionend", this.transitionEnd.bind(this))
    }
  }

  transitionEnd() {
    this.element.remove()
  }
}
