import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  selectAll(event) {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = true)
  }

  deselectAll(event) {
    this.element.querySelectorAll('input[type="checkbox"]').forEach(checkbox => checkbox.checked = false)
  }
}
