import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["indicator", "tab"]
  static classes = ["active"]

  connect() {
    // if there's no active element, mark the first as active
    let activeTab = this.tabTargets.find(target => target.classList.contains(this.activeClass))
    if (!activeTab) {
      this.tabTargets[0].classList.add('active')
    }
  }

  open(event) {
    let targetId = event.currentTarget.dataset.targetId
    let selectedIndex = this.tabTargets.findIndex(element => element.id === targetId)
    let selected = this.tabTargets[selectedIndex]
    let other = this.tabTargets.filter(element => element.id !== targetId)

    selected.classList.add(this.activeClass)
    other.forEach(element => element.classList.remove(this.activeClass))
    
    if (this.indicatorTarget) {
      this.indicatorTarget.style.left = `${event.currentTarget.offsetWidth * selectedIndex}px`
    }
  }
}
