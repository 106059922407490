import Track from "./track"
import Playlist from "./playlist"

export async function load(attributes) {
    const response = await fetch(attributes["json_path"])
    const body = await response.json()
    return buildPlayable(body)
}

function buildPlayable(response) {
  if (response.type === "audio" || response.type === "video") {
    return new Track(response)
  } else if (response.type === "playlist") {
    return new Playlist({ tracks: response.tracks, name: response.name, path: response.path })
  }
}
