import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "sortButton",
    "sortBlock",
    "sortAlphabeticalButton",
    "sortAlphabeticalInput",
    "sortHotTodayButton",
    "sortHotTodayInput",
    "trackFilters",
    "bandFilters",
    "typeFilters"
  ]
  static classes = ["active", "hidden"]

  static alphabeticallySortableTypes = ["band", "stage", "label", "partner", "fan"]

  connect() {
    this.updateUi()
  }

  toggleSort(event) {
    this.sortButtonTarget.classList.toggle(this.activeClass)
    this.sortBlockTarget.classList.toggle(this.hiddenClass)
  }

  selectType(event) {
    this.updateUi()
  }

  updateUi() {
    this.updateTrackFiltersVisibility()
    this.updateBandFiltersVisibility()
    this.updateSortAlphabeticalButtonVisibility()
    this.updateSortHotTodayButtonVisibility()
  }

  updateTrackFiltersVisibility() {
    if (this.selectedType === "track") {
      this.trackFiltersTarget.classList.remove(this.hiddenClass)
    } else {
      this.trackFiltersTarget.classList.add(this.hiddenClass)
    }
  }

  updateBandFiltersVisibility() {
    if (this.selectedType === "band") {
      this.bandFiltersTarget.classList.remove(this.hiddenClass)
    } else {
      this.bandFiltersTarget.classList.add(this.hiddenClass)
    }
  }

  updateSortAlphabeticalButtonVisibility() {
    if (this.constructor.alphabeticallySortableTypes.includes(this.selectedType)) {
      this.sortAlphabeticalButtonTarget.classList.remove(this.hiddenClass)
    } else {
      this.sortAlphabeticalButtonTarget.classList.add(this.hiddenClass)

      // If the user has selected the alphabetical sort option, switch back to the first sort option
      if (this.sortAlphabeticalInputTarget.checked) {
        this.sortBlockTarget.querySelector('input[type="radio"]').checked = true
      }
    }
  }

  updateSortHotTodayButtonVisibility() {
    if (this.selectedType === "band") {
      this.sortHotTodayButtonTarget.classList.remove(this.hiddenClass)
    } else {
      this.sortHotTodayButtonTarget.classList.add(this.hiddenClass)

      // If the user has selected the hot today sort option, switch back to the first sort option
      if (this.sortHotTodayInputTarget.checked) {
        this.sortBlockTarget.querySelector('input[type="radio"]').checked = true
      }
    }
  }

  get selectedType() {
    return this.typeFiltersTarget.querySelector(":checked").dataset.type
  }
}
