import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["embedSelect", "embedCode", "customSizeContainer", "customWidth", "customHeight"]

  connect() {
    this.embedSelectionChanged()
  }

  embedSelectionChanged(event) {
    this.customSizeContainerTarget.hidden = !this.hasCustomSize
    this.updateEmbedCode()
  }

  customWidthChanged(event) {
    const height = Math.ceil(this.customWidthTarget.value / (16/9))
    this.customHeightTarget.value = isNaN(height) ? 0 : height
    this.updateEmbedCode()
  }

  customHeightChanged(event) {
    const width = Math.ceil(this.customHeightTarget.value * (16/9))
    this.customWidthTarget.value = isNaN(width) ? 0 : width
    this.updateEmbedCode()
  }

  updateEmbedCode() {
    const selectedIndex = this.embedSelectTarget.selectedIndex
    const embedCode = this.embedSelectTarget.options[selectedIndex].dataset.code
    this.embedCodeTarget.value = this.hasCustomSize ? this.injectCustomDimensions(embedCode) : embedCode
  }

  injectCustomDimensions(code) {
    return code
      .replace(/width=".*?"/, 'width="' + this.customWidthTarget.value + '"')
      .replace(/height=".*?"/, 'height="' + this.customHeightTarget.value + '"')
  }

  get hasCustomSize() {
    return this.embedSelectTarget.value == "custom"
  }
}
