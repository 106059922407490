import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["list", "indicator"]

  static ANIMATION_DELAY = 4000

  connect() {
    this.currentIndex = 0
    this.updateIndicators()
    this.autoScroll()
    this.isAutoScrolling = false
  }

  next(event) {
    if (this.currentIndex < this.listTarget.children.length - 1) {
      this.currentIndex++
    } else {
      this.currentIndex = 0 // Reset to the first card
    }
    this.updateCarousel(event)
  }

  prev(event) {
    if (this.currentIndex > 0) {
      this.currentIndex--
    } else {
      this.currentIndex = this.listTarget.children.length - 1 // Go to the last card
    }
    this.updateCarousel(event)
  }

  goTo(event) {
    const index = event.currentTarget.dataset.carouselSlide
    this.currentIndex = parseInt(index)
    this.updateCarousel()
  }

  updateCarousel(event) {
    const offset = this.currentIndex * this.listTarget.children[0].clientWidth
    this.listTarget.scrollTo({left: offset, behavior: 'smooth'}) 
    this.updateIndicators()
    if (event && this.interval) {
      clearInterval(this.interval)
    }
  }

  updateIndicators() {
    this.indicatorTargets.forEach((indicator, index) => {
      const isActive = index === this.currentIndex
      indicator.style.backgroundColor = isActive ? "red" : "gray"
    })
  }

  autoScroll() {
    let self = this
    this.interval = setInterval(function() {
      self.isAutoScrolling = true
      self.next()
      setTimeout(() => {
        self.isAutoScrolling = false;
      }, 500);
    }, this.constructor.ANIMATION_DELAY)
  }

  mouseenter() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  mouseleave() {
    this.autoScroll()
  }

  touchmove() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  scroll() {
    if (this.interval && !this.isAutoScrolling) {
      clearInterval(this.interval)
    }
    let gap = this.listTarget.style.gap
    let active = this.listTarget.scrollLeft / (this.listTarget.children[0].clientWidth+gap)
    let isAtRight = this.listTarget.scrollLeft + this.listTarget.clientWidth >= this.listTarget.scrollWidth - 1
    if (isAtRight) {
      this.currentIndex = this.listTarget.children.length - 1
    } else {
      this.currentIndex = Math.round(active)
    }
    this.updateIndicators()
  }
}
