import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["play", "pause", "index"]
  static classes = ["hidden"]
  static values = {
    attributes: Object
  }

  pause() {
    this.dispatch("pause")
  }

  next() {
    this.dispatch("next")
  }

  prev() {
    this.dispatch("prev")
  }

  hidePlayButton() {
    this.playTargets.forEach((item) => {
      item.classList.add(this.hiddenClass)
      item.classList.remove(this.showClass)
    })
  }

  showPlayButton() {
    this.playTargets.forEach((item) => {
      item.classList.remove(this.hiddenClass)
      item.classList.add(this.showClass)
    })
  }

  hidePauseButton() {
    this.pauseTargets.forEach((item) => {
      item.classList.remove(this.showClass)
      item.classList.add(this.hiddenClass)
    })
  }

  showPauseButton() {
    this.pauseTargets.forEach((item) => {
      item.classList.remove(this.hiddenClass)
      item.classList.add(this.showClass)
    })
  }

  updateIndex(e) {
    this.indexTarget.innerText = e.detail._index + 1
  }
}
