import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["addButton", "removeButton"]
  static classes = ["hidden"]

  connect() {
    this.updateButtonVisibility()
  }

  async add(event) {
    event.preventDefault()

    const url = this.getFormAction(event.target)
    if (!url) {
      console.error("No URL found for add action")
      return
    }

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        }
      });

      if (response.ok) {
        this.refreshFrame()
      } else {
        console.error("Failed to add track")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  async remove(event) {
    event.preventDefault()

    const url = this.getFormAction(event.target)
    if (!url) {
      console.error("No URL found for remove action");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (response.ok) {
        this.refreshFrame()
      } else {
        console.error("Failed to remove track")
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  getFormAction(target) {
    if (target.closest('form')) {
      return target.closest('form').action
    } else {
      console.error("Target is not a form element or missing action attribute")
      return null
    }
  }

  updateButtonVisibility() {
    if (!this.hasAddButtonTarget || !this.hasRemoveButtonTarget) {
      console.error("Missing target elements")
      return
    }

    const added = this.element.dataset.added === "true"

    if (added) {
      this.addButtonTarget.classList.add(this.hiddenClass)
      this.removeButtonTarget.classList.remove(this.hiddenClass)
    } else {
      this.addButtonTarget.classList.remove(this.hiddenClass)
      this.removeButtonTarget.classList.add(this.hiddenClass)
    }
  }

  refreshFrame() {
    const frame = this.element.closest("turbo-frame")
    if (frame) {
      frame.src = frame.src
    } else {
      console.error("Turbo frame not found")
    }
  }
}
