import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["favourite", "unfavourite"]
  static classes = ["hidden"]

  favourite(event) {
    event.preventDefault()
    event.stopPropagation()
    this.request(this.favouriteTarget.querySelector('button').getAttribute('data-url'), 'POST')
    this.showUnfavouriteButton()
  }

  unfavourite(event) {
    event.preventDefault()
    event.stopPropagation()
    this.request(this.unfavouriteTarget.querySelector('button').getAttribute('data-url'), 'DELETE')
    this.showFavouriteButton()
  }

  request(url, method) {
    let self = this
    fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'text/javascript',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error – status: ${response.status}`)
      }
      if (response.redirected) {
        window.location.href = response.url
      }
    })
    .catch(error => {
      console.error('Error:', error)
      // reset buttons
      if (method == 'POST') {
        self.showFavouriteButton()
      } else {
        self.showUnfavouriteButton()
      }
    })
  }

  showFavouriteButton() {
    this.favouriteTarget.classList.remove(this.hiddenClass)
    this.unfavouriteTarget.classList.add(this.hiddenClass)
  }

  showUnfavouriteButton() {
    this.favouriteTarget.classList.add(this.hiddenClass)
    this.unfavouriteTarget.classList.remove(this.hiddenClass)
  }
}
