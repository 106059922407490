import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "collapsible" ]
  static classes = [ "active" ]

  toggle(event) {
    event.target.classList.toggle(this.activeClass)
    this.collapsibleTargets.forEach(target => {
      target.classList.toggle(this.activeClass)
    })
  }
}
