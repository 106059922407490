import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "badge"]
  static values = {
    countSelector: String
  }
  static classes = ["active", "hidden"]

  connect() {
    this.update()
  }

  update() {
    const count = document.querySelectorAll(this.countSelectorValue).length
    if (count > 0) {
      this.badgeTarget.textContent = count
      this.badgeTarget.classList.remove(this.hiddenClass)
      this.buttonTarget.classList.add(this.activeClass)
    } else {
      this.badgeTarget.classList.add(this.hiddenClass)
      this.buttonTarget.classList.remove(this.activeClass)
    }
  }
}
