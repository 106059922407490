import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    targetId: String
  }
  static classes = ["show"]

  connect() {
    this.body = document.querySelector("body")
  }

  open(event) {
    const target = document.querySelector(this.targetIdValue)
    target.classList.add(this.showClass)
    this.body.style.overflow = "hidden"

    const data = event.currentTarget.dataset
    this.dispatch("open", { detail: { ...data, target } })
  }

  close() {
    const target = document.querySelector(this.targetIdValue)
    target.classList.remove(this.showClass)
    this.body.style.overflow = "auto"

    this.dispatch("close", { detail: { target } })
  }
}
