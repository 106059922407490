export default class PlayableList {
  constructor({ history = [], current = null, queue = [] } = {}) {
    this.history = history
    this.current = current
    this.queue = queue
  }

  skipBackward() {
    if (!this.current) {
      return
    }

    if (this.current.hasPrevious()) {
      this.current.skipBackward()
    } else {
      if (this.history.length === 0) {
        return
      }

      const last = this.history[this.history.length - 1]
      this.queue.unshift(this.current)
      this.current = last
    }
  }

  skipToTrack(index) {
    this.current.skipToTrack(index);
  }

  skipForward() {
    if (!this.current) {
      return
    }

    if (this.current.hasNext()) {
      this.current.skipForward()
    } else {
      if (this.queue.length === 0) {
        return
      }
      this.history.push(this.current)
      this.current = this.queue.shift()
    }
  }

  playNow(playable) {
    if (this.current) {
      this.history.push(this.current)
    }
    this.current = playable
    this.queue = []
  }

  get currentTrack() {
    return this.current.current
  }
}
